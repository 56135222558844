import React from 'react';
import ReactPlayer from 'react-player';
import '../assets/css/slide.css';

const VideoPly = () => {

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className=' max-w-6xl mx-auto mt-2'>
    <div className='player-wrapper'>
      <ReactPlayer
        className='react-player'
        url='https://youtu.be/PYi98aS58OI'
        controls={true}
        width='100%'
        height='100%' 
      />
    </div>
    </div>
  )
}

export default VideoPly;

