import React, { useState } from 'react';
import { Router } from '@reach/router';
import MainNav from './components/MainNav';
import HomePage from './components/HomePage';
import Invita from './components/Invita';
import Carousel from './components/Carousel';
import FotoGallery from './components/FotoGallery';
import VideoPly from './components/VideoPly';
import Escudo from './components/Escudo';
import navLinks from './assets/data/navlinks';

function App() {
  
  const [showEscudo, setShowEscudo] = useState(false);

  const showEscudoH = () => {
    setShowEscudo(true);
  }

  const hideEscudoH = () => {
    setShowEscudo(false);
  }

  return (
    <div className="App bg-white md:px-1">
      <MainNav navLinks = { navLinks} showE = {showEscudoH} hideE = {hideEscudoH}/>
      <div className='pt-10 relative'>
        { console.log("Show " + showEscudo) }
        { showEscudo && <Escudo /> }
        {/* Esto es para produccion porque llega con /bit y no se ejecuta el router path del / */}
        {/* { window.location.pathname==='/bit' && <HomePage /> } */}
        <Router>
          <HomePage path='/' />
          <Invita path='/invita' />
          <Carousel path='/menu' />
          <FotoGallery path='/foto' /> 
          <VideoPly path='/video' />
        </Router>
      </div>
    </div>
  );
}

export default App;
