const navLinks = [
  {
    text: 'Home',
    path: '/',
    icon: 'icon-home'
  },
  {
    text: 'Invitación',
    path: '/invita',
    icon: 'icon-open-book'
  },
  {
    text: 'Menu',
    path: '/menu',
    icon: 'icon-drink'
  },
  {
    text: 'Fotos',
    path: '/foto',
    icon: 'icon-camera'
  },
  {
    text: 'Video',
    path: '/video',
    icon: 'icon-youtube'
  }
]

export default navLinks;
