import React, { useState, useEffect} from 'react';
import Escudo from '../assets/img/escudo.png';
import BarcoIBT from '../assets/img/barcoibt.png';
import Invitados from '../assets/data/invitados.json';

const HomePage = () => {
  const [opacity, setOpacity] = useState(1);

  const invs = Invitados.map((invitado, index) => {
    invitado.NameG1 = invitado.NameG1.toString();
    invitado.NameG2 = invitado.NameG2.toString();
    return invitado;
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    setOpacity(0);
    setTimeout(() => {
      setOpacity('');
    }, 2000);
  }, []);

  return (
    <>
      <div className="flex justify-center w-full bg-white mx-auto mb-5 relative">
        <img src={BarcoIBT} style={{opacity}} alt="" className='tbifade pt-5 transition-opacity duration-1000 opacity-0 hover:opacity-100 absolute top-0' />
      </div>
      <div className="flex justify-center w-full bg-white mx-auto mb-5 h-auto">
          <img src={Escudo} alt="" className=''/>
      </div>
      {invs.map((inv, index) => 
        <div key={index} className="flex my-2">
          <div className="w-0 sm:w-1/12"></div>
          <div className="w-full mx-2 sm:mx-0 sm:w-10/12 flex">
            <div className="w-6/12 text-right pr-2">
              {inv.NameG2!=="" && <span className="text-tbi-500 sm:font-semibold">{inv.NameG1}</span>}
            </div>
            <div className="w-auto">
              {inv.NameG2!=="" ? <span className="text-tbi-500 sm:font-semibold">&</span> : <div className="w-48 flex justify-center"><span className="text-tbi-500 sm:font-semibold">{inv.NameG1}</span></div>}
            </div>
            <div className="w-6/12 text-left pl-2">
              <span className="text-tbi-500 sm:font-semibold">{inv.NameG2}</span>
            </div>
          </div>
          <div className="w-0 sm:w-1/12"></div>
        </div>
      )}
    </>
  )
}

export default HomePage;
