import React from 'react';
import { Link } from "@reach/router";
import Sprite from '../assets/img/sprite.svg';

const MainNav = ({navLinks, showE, hideE }) => {
   return (
    <nav className="flex justify-center bg-gray-300 p-2 mt-0 fixed w-full z-10 top-0">
        <ul className="flex justify-center items-center m-0 p-0 h-full ml-1 sm:ml-10">
          {navLinks.map((link, index) => 
            <li key={index} className="flex items-center mr-3 md:mr-20 ml-0 my-0 p-0">
              <div>
                <Link to={link.path}  onClick={link.path==='/' || link.path==='/video'?hideE:showE}>
                  <svg className="fill-current text-yellow-700 inline-block h-3 w-3 align-baseline">
                    <use href={Sprite + '#' + link.icon}></use>
                  </svg>
                </Link>
              </div>
              <div>  
                <Link to={link.path} onClick={link.path==='/' || link.path==='/video'?hideE:showE}>
                  <span className='text-yellow-900 mt-1'>
                    { link.text }
                  </span>
                </Link>
              </div>
            </li>
          )}
        </ul>
    </nav>
  )
}

export default MainNav;
