import React, {useState, useEffect} from 'react';
import ImageCard from './ImageCard';
import Sprite from '../assets/img/sprite.svg';
import Fotos from '../assets/data/fotos.json';
import AniCruL from '../assets/img/anicruleft.png';
import AniCruR from '../assets/img/anicruright.png';

const FotoGallery = () => {
  const [images, setImages] = useState([]);
  const [page, setPage] = useState(0);
  const [maxPage, setMaxPage] = useState(0);
  const [cuCat, setCuCat] = useState(0);
  const [nameCat, setNameCat] = useState("");
  const [lastCat, setLastCat] = useState(0);

  const LoadImgs = () => { 
    const fxp = 16;
    setLastCat(Fotos.length-1);
    var catnm = Fotos[cuCat].catName;
    var urls = Fotos[cuCat].fotoUrl;
    var maximg = urls.length;
    setNameCat(catnm);
    setMaxPage(parseInt(maximg/fxp) + (maximg % fxp > 0 ? 1 : 0));
    var imgarr = [];
    var j = (page-1)*fxp;
    for (var i = 0; i < fxp; i++) {
      j = j + 1; 
      if (j <= maximg)
        imgarr.push(
          {
            cat: catnm,
            name: urls[j-1],
          }
        );
    }
    return imgarr;
  } 

  useEffect(() => {
    (page > 0) ? setImages(LoadImgs()) : setImages([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  useEffect(() => {
    (page !== 1) ? setPage(1) : setImages(LoadImgs());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cuCat]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div>
        <div className='max-w-sm md:max-w-2xl flex justify-between mx-auto h-full mt-1 sm:mt-5'>
          <div className="object-cover w-12 ml-3 cursor-pointer">
            <img src={AniCruL} alt="" className="h-10" onClick={() => page>1?setPage(page-1):setPage(page)} />
          </div>
          <div>
            <button
              className="bg-tbi-100 hover:bg-tbi-300 pl-1 pr-1 w-40 text-tbi-900 font-bold rounded-full focus:outline-none flex items-center justify-between" 
              >
              <svg className="fill-current text-yellow-900 inline-block h-8 w-8 align-baseline pr-2"
                onClick={() => cuCat>1?setCuCat(cuCat-1):setCuCat(0)}>
                <use href={Sprite + '#icon-arrow-bold-left'}></use>
              </svg>
              {nameCat}
              <svg className="fill-current text-yellow-900 inline-block h-8 w-8 align-baseline pl-2"
                onClick={() => cuCat<lastCat?setCuCat(cuCat+1):setCuCat(lastCat)}>
                <use href={Sprite + '#icon-arrow-bold-right'}></use>
              </svg>
            </button>
         </div>
          <div className="object-cover w-12 mr-3 cursor-pointer">
            <img src={AniCruR} alt=""  className="h-10" onClick={() => page<maxPage?setPage(page+1):setPage(page)} />
          </div>
        </div>
        <div className='max-w-sm md:max-w-2xl flex justify-between mx-auto h-full mt-1 sm:mt-3'>
          <div className="grid grid-cols-2 mx-1 my-2 gap-4 md:grid-cols-4 md:col-gap-6 md:row-gap-6">
            {images.map((image, index) => (
              <ImageCard key={index} image={image} />
            ))}
          </div>
        </div>
      </div>
      <div id='divco' class=""></div>
    </>
  )
}

export default FotoGallery;
