import React from 'react';
import Escud from '../assets/img/escudo.jpg';

const Escudo = () => {
  return (
    <div className="max-w-sm md:max-w-2xl flex justify-center w-full bg-white mx-auto z-10">
      <img src={Escud} alt="Escudo" className="w-20 mx-auto" />
    </div>
  )
}

export default Escudo;
