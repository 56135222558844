import React from 'react';
import hpage1 from '../assets/img/hpage1.jpg';
import hpage2 from '../assets/img/hpage2.jpg';

const HomePage = () => {

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  return (
    <div className="md:flex justify-center w-full bg-white mx-auto mb-5">
      <div className="md:max-w-md rounded overflow-hidden shadow-lg h-full">
        <img src={hpage1} alt=""/>
      </div>
      <div className="md:max-w-md rounded overflow-hidden shadow-lg md:px-3 md:pt-5">
        <img src={hpage2} alt=""/>
      </div>
    </div>
  )
}

export default HomePage;
