import React, { useEffect } from 'react';
import MenuD from '../assets/img/menud.jpg';
import MenuG1 from '../assets/img/menug1.jpg';
import MenuG2 from '../assets/img/menug2.jpg';
import MenuG3 from '../assets/img/menug3.jpg';


const Carousel = () => {
  var slideIndex = 0;

  let timer;

  function showSlides() {
    var i;
    var slides = document.getElementsByClassName("mySlides");
    for (i = 0; i < slides.length; i++) {
      slides[i].style.display = "none";  
    }
    slideIndex++;
    if (slideIndex > slides.length) {slideIndex = 1}    
    slides[slideIndex-1].style.display = "block";  
    timer = setTimeout(showSlides, 2000); 
  }

  useEffect(() => {
    showSlides();
    return () => clearTimeout(timer);
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="md:flex justify-center w-full bg-white mx-auto mb-5">
      <div className="slideshow-container">
        <div className='mySlides fade md:max-w-md rounded overflow-hidden shadow-lg h-full'>
          <img src={MenuG1} alt="" />
        </div>
        <div className='mySlides fade md:max-w-md rounded overflow-hidden shadow-lg h-full'>
          <img src={MenuG2} alt="" />
        </div>
        <div className='mySlides fade md:max-w-md rounded overflow-hidden shadow-lg h-full'>
          <img src={MenuG3} alt="" />
        </div>
      </div>
      <div className="md:max-w-md rounded overflow-hidden shadow-lg md:px-3 md:pt-5">
        <img src={MenuD} alt="Comidas"/>
      </div>
    </div>
  )
}

export default Carousel;
