import React, {useState} from 'react';
import Fotos from '../assets/data/fotos.json';
import Sprite from '../assets/img/sprite.svg';

const ImageCard = ({ image }) => {
  const urlFoto = 'http://ibesame.com/fotos/';
  const urlThumbnail = 'http://ibesame.com/thumbnails/';
  const [showModal, setShowModal] = useState(false);
  const [imgName, setImgName] = useState('');

  const InitFoto = (fname) => {
    document.getElementById("divco").classList.add("cover");
    setImgName(fname);
    setShowModal(true);
  }

  const NextFoto = (fcat) => {
    const curcat = Fotos.filter((cat) => cat.catName===fcat);
    var ndx = curcat[0].fotoUrl.findIndex(surl => surl===imgName);
    if (ndx < curcat[0].fotoUrl.length-1) ndx++;
    setImgName(curcat[0].fotoUrl[ndx].toString());
  }

  const PrevFoto = (fcat) => {
    const curcat = Fotos.filter((cat) => cat.catName===fcat);
    var ndx = curcat[0].fotoUrl.findIndex(surl => surl===imgName);
    if (ndx > 0) ndx--;
    setImgName(curcat[0].fotoUrl[ndx].toString());
  }

  return (
    <div className="shadow-lg w-full">
      <img src={urlThumbnail + image.name} alt="" className="object-cover w-full h-24" onClick={() => InitFoto(image.name) }/>
      {showModal ? (
        <>
          <div
            className="justify-center mt-12 flex overflow-x-hidden overflow-y-scroll fixed inset-0 z-50   outline-none  focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg flex flex-col w-full bg-tbi-200 outline-none focus:outline-none relative overflow-hidden">
                <img src={urlFoto + imgName} alt="" className="object-contain object-top w-full max-h-screen"/>
                {/*header*/}
                <div className="flex justify-between items-center absolute top-0 left-0 pl-2 w-full">
                  <span className="bg-transparent text-orange-500 opacity-5 h-6 w-8/12 text-sm block">
                    {image.cat + ' ' + imgName.substring(imgName.length-8).substring(0,4)}
                  </span>
                  <button
                    className="mt-2 mr-2 border-0 text-black float-right -text-3xl outline-none focus:outline-none bg-tbi-300  w-6 h-6 rounded-full inline-block align-text-top p-0"
                    onClick={() => { document.getElementById("divco").classList.remove("cover"); 
                     setShowModal(false) }}
                  >X</button>
                </div>
                {/*body*/}
                <div className="flex justify-between items-center absolute mt-48 left-0 pl-2 w-full">
                  <button
                    className="border-0 text-black float-lef -text-3xl outline-none focus:outline-none bg-tbi-300  w-8 h-8 rounded-full inline-block align-text-top pl-1"
                    onClick={() => PrevFoto(image.cat)}
                  >
                    <svg className="fill-current text-yellow-900 inline-block h-8 w-8 align-baseline pr-2">
                      <use href={Sprite + '#icon-arrow-bold-left'}></use>
                  </svg>
                  </button>
                  <button
                    className="mt-2 mr-2 border-0 text-black float-right -text-3xl outline-none focus:outline-none bg-tbi-300  w-8 h-8 rounded-full inline-block align-text-top pl-1"
                    onClick={() => NextFoto(image.cat)}
                  >
                    <svg className="fill-current text-yellow-900 inline-block h-8 w-8 align-baseline pr-2">
                      <use href={Sprite + '#icon-arrow-bold-right'}></use>
                    </svg>
                  </button>
                </div>
                {/*footer*/}
              </div>
            </div>
          </div>
        </>
      ) : null}
    </div>
  )
}

export default ImageCard;
